import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
   baseURL: 'https://backend.ventancatgrup.com/',
  // baseURL: 'https://ventancat.evotic.es/',
  //baseURL: 'http://localhost:8000/',
  timeout: 5000,
  Accept: 'application/json', 
  withCredentials: true,
})

Vue.prototype.$http = axiosIns

export default axiosIns
