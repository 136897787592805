export default [
  {
    title: 'Inicio',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Pedido',
    route: { name: 'order', params: { new: 1 } },
    icon: 'FilePlusIcon',
  },
  {
    title: 'Mis Pedidos',
    route: 'orders',
    icon: 'ListIcon',
  },
  // {
  //   title: 'Colaboradores',
  //   route: 'partners',
  //   icon: 'GlobeIcon',
  // },
  {
    title: 'Promociones',
    route: 'promotions',
    icon: 'GiftIcon',
    tag: 'Premium',
    tagVariant: 'light-success',
  },
  {
    title: 'Contactar',
    route: 'contact',
    icon: 'MailIcon',
  }
]
